import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import {
  createReactRouterV6Options,
  getWebInstrumentations,
  initializeFaro,
  ReactIntegration,
} from '@grafana/faro-react'
import { TracingInstrumentation } from '@grafana/faro-web-tracing'
import {
  GRAFANA_FARO_URL,
  GRAFANA_FARO_APP_NAME,
  GRAFANA_FARO_APP_ENV,
  ADD_IN_HOST,
} from '@src/constants'

if (!ADD_IN_HOST.includes('local')) {
  initializeFaro({
    url: GRAFANA_FARO_URL,
    app: {
      name: GRAFANA_FARO_APP_NAME,
      version: '1.0.0',
      environment: GRAFANA_FARO_APP_ENV,
    },

    instrumentations: [
      // Mandatory, omits default instrumentations otherwise.
      ...getWebInstrumentations(),

      // Tracing package to get end-to-end visibility for HTTP requests.
      new TracingInstrumentation(),

      // React integration for React applications.
      new ReactIntegration({
        router: createReactRouterV6Options({
          createRoutesFromChildren,
          matchRoutes,
          Routes,
          useLocation,
          useNavigationType,
        }),
      }),
    ],
  })
}
